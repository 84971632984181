import * as s from './ArAboutLogos.module.scss'

import { ArContainer } from 'src/components/ArContainer'

import LondonSkyline from 'src/assets/images/london-skyline.png'
import PartnerLogos from 'src/assets/images/partner-logos.png'

import React from 'react'
import { TArAboutLogosProps } from './ArAboutLogos.types'

export const ArAboutLogos: React.FC<TArAboutLogosProps> = () => {
  return (
    <ArContainer className={s.container}>
      <div className="grid lg:grid-cols-2 gap-12">
        <div className="grid">
          <div className="flex flex-col justify-between h-full">
            <div>
              <h2>Partnerships</h2>
              <p>We are proud to be partnered with some of the best organisations in the Lloyd's and London markets.</p>
            </div>
            <div className="pt-8 lg:pt-0">
              <img src={PartnerLogos} alt="Artificial Partner Logos" />
            </div>
          </div>
        </div>
        <div className="grid object-fill">
          <img src={LondonSkyline} alt="London Skyline" className="object-cover w-full h-full rounded-2xl" />
        </div>
      </div>
    </ArContainer>
  )
}
