import * as s from './ArAboutHero.module.scss'

import { ArContainer } from 'src/components/ArContainer'
import { METRICS } from 'src/config/metrics'
import React from 'react'
import { TArAboutHeroProps } from './ArAboutHero.types'
import { parseRichText } from 'src/utils/parseRichText'

export const ArAboutHero: React.FC<TArAboutHeroProps> = () => {
  return (
    <ArContainer className={s.container}>
      <h1>We’re building technology for the next generation of insurance.</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: parseRichText([
            'At Artificial, we’re using the latest cloud-based tools and machine learning techniques to give commercial insurers and brokers more power over their data.',
            { type: 'br' },
            { type: 'br' },
            'We partner with global insurers and brokers to facilitate faster, easier and more efficient placement of commercial and specialty risk, backed by our powerful Contract Builder and Underwriting Platform.',
            { type: 'br' },
            { type: 'br' },
            'The next generation of insurance is here - so why not join us?',
          ]),
        }}
      />
    </ArContainer>
  )
}
