import React from 'react'

import { ArAboutHero } from 'src/components/ArAboutHero'
import { ArAboutOurTeam } from 'src/components/ArAboutOurTeam'

import { ArSeo } from 'src/components/ArSeo'
import { ROUTES } from 'src/config/routes'

import { ArAboutLogos } from 'src/components/ArAboutLogos'
import { ArContainer } from 'src/components/ArContainer'
import { ArAboutNewsLinks } from 'src/components/ArAboutNewsLinks'
import { ArExtraFeatures } from 'src/components/ArExtraFeatures'
const AboutPage = () => {
  return (
    <>
      <ArSeo route={ROUTES.about()} />
      <ArAboutHero />
      <ArAboutOurTeam />
      <ArAboutLogos />
      <ArContainer className="pt-0">
        <ArAboutNewsLinks />
      </ArContainer>
    </>
  )
}

export default AboutPage
