import ImageTeamAlexisRenaudin from 'src/assets/images/TeamAlexisRenaudin.png'
import ImageTeamAnnaBurge from 'src/assets/images/TeamAnnaBurge.png'
import ImageTeamArturKisiolek from 'src/assets/images/TeamArturKisiolek.png'
import ImageTeamDavidKing from 'src/assets/images/TeamDavidKing.png'
import ImageTeamDeana from 'src/assets/images/TeamDeana.png'
import ImageTeamJohnyBridges from 'src/assets/images/TeamJohnyBridges.png'
import ImageTeamMartin from 'src/assets/images/TeamMartin.png'
import ImageTeamTimBates from 'src/assets/images/TeamTimBates.png'

export const OUR_TEAM = [
  {
    name: 'Martin Reith',
    position: 'Chairman',
    image: ImageTeamMartin,
    linkedIn: 'https://www.linkedin.com/in/martin-reith-41444316/',
  },
  {
    name: 'David King',
    position: 'Co-founder & Co-CEO',
    image: ImageTeamDavidKing,
    linkedIn: 'https://www.linkedin.com/in/malarkeyking',
  },
  {
    name: 'Johnny Bridges',
    position: 'Co-founder & Co-CEO',
    image: ImageTeamJohnyBridges,
    linkedIn: 'https://www.linkedin.com/in/johnnybridges',
  },
  {
    name: 'Deana Murfitt',
    position: 'COO',
    image: ImageTeamDeana,
    linkedIn: 'https://www.linkedin.com/in/deanamurfitt/',
  },
  {
    name: 'Artur Kisiolek',
    position: 'CTO',
    image: ImageTeamArturKisiolek,
    linkedIn: 'https://www.linkedin.com/in/arturkisiolek',
  },
  {
    name: 'Alexis Renaudin',
    position: 'Head of Data',
    image: ImageTeamAlexisRenaudin,
    linkedIn: 'https://www.linkedin.com/in/arenaudin/',
  },
  {
    name: 'Tim Bates',
    position: 'Head of Product',
    image: ImageTeamTimBates,
    linkedIn: 'https://www.linkedin.com/in/tim-bates-6a8bb535',
  },
  {
    name: 'Anna Burge',
    position: 'Head of Marketing',
    image: ImageTeamAnnaBurge,
    linkedIn: 'https://www.linkedin.com/in/anna-burge-b35083ab/',
  },
]
