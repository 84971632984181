import * as s from './ArAboutNewsLinks.module.scss'
import React from 'react'
import SvgArrowRight from 'src/assets/svg/arrow-right-3.svg'

const links = [
  {
    url: 'https://artificial.io/company/blog/artificial-labs-raises-gbp8m-series-a-round-to-drive-growth-and-continue/',
    title: 'Artificial raises £8M Series A+',
  },
  {
    url: 'https://artificial.io/company/blog/ppl-and-artificial-labs-sign-long-term-partnership-to-redefine-the-landscape',
    title: 'Artificial and PPL sign Contract Builder partnership',
  },
  {
    url: 'https://www.insuranceinsider.com/article/2c3n63quzbidlfnhfyebk/london-market-section/apollo-and-artificial-labs-launch-algorithmic-underwriting-collaboration',
    title: 'Artificial and Apollo announce Smart Follow collaboration',
  },
  {
    url: 'https://www.insuranceinsider.com/article/2a95mbt75nvuaiy965dz4/london-market-section/algorithmic-underwriting-insurtech-artificial-labs-raises-9-5mn',
    title: 'Artificial raises £9.5M Series A funding',
  },
]

export const ArAboutNewsLinks: React.FC = () => {
  return (
    <div className={s.container}>
      <h3>News</h3>
      <ul>
        {links.map((item, i) => (
          <li key={i}>
            <a href={item.url} target="_blank" rel="noreferrer">
              <p>{item.title}</p>
              <SvgArrowRight />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
